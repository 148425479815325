@font-face {
  font-family: 'cera-pro';
  src: local('cera-pro'), url(CeraPro-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'cera-pro-bold';
  src: local('cera-pro-bold'), url(CeraPro-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'cera-pro-light';
  src: local('cera-pro-light'), url(CeraPro-Light.ttf) format('truetype');
}
